@tailwind base;
@tailwind components;
@tailwind utilities;

.bothCenter {
  @apply flex items-center justify-center;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.card-empty-img {
  border-radius: 130px;
  width: 50px;
  height: 50px;
  padding: 20p;
  margin: 10px;
}
body,
.App {
  background: white;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.fixedContainer {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
}
.img-preview {
  width: 100px !important;
  height: 100px !important;
}
.school-pay-root .MuiFormControlLabel-root {
  margin-bottom: 10px;
}
.school-pay-list .MuiFormControlLabel-label {
  flex-grow: 1;
}
.school-pay-list .MuiFormControlLabel-root {
  margin-left: 16px;
}
.pay-modal .MuiAlert-message {
  text-align: center;
  width: 100%;
}
.learn-header{
  
    color: white;
    padding: 20px 20px;
    margin-top: 20px;
}

.playback-rate {
  display: none;
}

.page-container {
  height: 100%;
  width: 100%;
}

.items-center img{
  max-width: 100%;
  max-height: 100%;
}